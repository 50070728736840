import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import { DataTableDialogFooter } from "../../common";
import { Toast } from "primereact/toast";
import { ICategory } from "@/models/Entities/category";
import {
  IAllMainboardItems,
  ILabelDefinition,
  IMainboardItems,
} from "@/models";
import {
  deleteMainBoardItem,
  getLabelsOfCategory,
  getMbisOfCategory,
  removeLabelFromCategory,
} from "../../../../services";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Row } from "react-bootstrap";
import { AddMBIDialog } from "../../mainboarditem";
import { toastError, toastSuccess } from "../../../../utils";
import AddLabelToCategoryDialog from "./AddLabelToCategoryDialog";
import { Badge } from "primereact/badge";

interface LabelsOfCategoryDialogProps {
  visible: boolean;
  onHide: () => void;
  selectedCategory: ICategory;
}

const LabelsOfCategoryDialog: React.FC<LabelsOfCategoryDialogProps> = ({
  visible,
  onHide,
  selectedCategory,
}) => {
  const toast = useRef<Toast>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState<ILabelDefinition | null>(
    null
  );

  const [labelsOfContent, setLabelsOfContent] = useState<
    ILabelDefinition[] | undefined
  >([]);
  const [showAddLabelDialog, setshowAddLabelDialog] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(true);

  useEffect(() => {
    getLabelsOfCategory(selectedCategory?.id).then((response) => {
      //console.log("getMbisOfCategory.response:", response);
      if (response && response.length > 0) {
        setLabelsOfContent(response);
        //setlsLoading(false);
      }
    });
  }, [selectedCategory, triggerRefresh, visible]);

  function handleSave() {}

  function hideDialog() {
    setLabelsOfContent(undefined);
    onHide();
  }

  const openAddLabelDialog = () => {
    setshowAddLabelDialog(true);
  };

  const hideAddLabelDialog = () => {
    setshowAddLabelDialog(false);
  };

  const refreshTable = () => {
    setTriggerRefresh(!triggerRefresh);
  };

  const openDeleteLabelDialog = (rowData?: ILabelDefinition) => {
    rowData && setSelectedLabel(rowData);

    //console.log("selectedCategory?.id:", selectedCategory?.id);
    //console.log("rowData?.id:", rowData?.id);

    removeLabelFromCategory(selectedCategory?.id, rowData?.id).then((res) => {
      //console.log("delete res", res);
      if (res.status === 200) {
        if (res.data.code === 200) {
          toastSuccess(toast, "Label Removed. " + rowData?.name);
          refreshTable();
          //setUpdateTrigger((prev) => !prev);
        } else {
          //console.log("delete res.data.data", res.data.data);
          toastError(toast, "Label Remove Failed." + res.data.message);
        }
      } else {
        toastError(toast, "Label Delete Failed. Status:" + res.data.status);
      }
    });
    setSelectedLabel(null);
  };

  return (
    <>
      <Dialog
        visible={visible}
        style={{ width: "40%", minHeight: "200px" }}
        header={"Label Management for " + selectedCategory?.name || ""}
        modal
        className="p-fluid"
        footer={
          <>
            <Button
              label={"Close"}
              icon="pi pi-times"
              className="p-button-text"
              onClick={hideDialog}
            />
          </>
        }
        onHide={hideDialog}
      >
        <Toast ref={toast} />
        <Row style={{ marginBottom: "20px", textAlign: "right" }}>
          <Button
            label={"Add Label"}
            icon="pi pi-plus"
            className="p-button-success mr-2"
            style={{ width: "150px" }}
            onClick={openAddLabelDialog}
          />
        </Row>
        <DataTable
          value={labelsOfContent}
          selection={selectedLabel}
          onSelectionChange={(e) => setSelectedLabel(e.value as ILabelDefinition)}
          title="mainboardItems"
        >
          <Column
            field="ID"
            header="id"
            body={(rowData) => <>{rowData.id}</>}
            sortable
          />
          <Column
            field="name"
            header="name"
            body={(rowData) => <>{rowData.name}</>}
            sortable
          />
          <Column
            field="homeScreenVisibility"
            header="Home Visibility"
            body={(rowData) => (
              <>
                {rowData.homeScreenVisibility ? (
                  <Badge value="OK" severity="success" />
                ) : (
                  <Badge value="NOK" severity="danger" />
                )}
              </>
            )}
            sortable
          />

          <Column
            field="viewType"
            header="View Type"
            body={(rowData) => <>{rowData.viewType}</>}
            sortable
          />
          <Column
            header=""
            style={{ textAlign: "right" }}
            body={(rowData: ILabelDefinition) => (
              <>
                <Button
                  icon="pi pi-times p-button-rounded"
                  className="p-button-outlined p-button-rounded p-button-danger m-1"
                  onClick={() => openDeleteLabelDialog(rowData)}
                  style={{ width: "2rem", height: "2rem" }}
                />
              </>
            )}
          />
        </DataTable>
      </Dialog>
      <AddLabelToCategoryDialog
        visible={showAddLabelDialog}
        onHide={hideAddLabelDialog}
        refreshTable={refreshTable}
        categoryId={selectedCategory?.id}
      />
    </>
  );
};

export default LabelsOfCategoryDialog;

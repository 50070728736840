import { getCategories } from "../../../../services";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { ICategory } from "@/models/Entities/category";
import { categoryFilters, initialCategory } from "../../../../constants";
import { DataTableCrud, DataTableSearchHeader } from "../../common";
import { Column } from "primereact/column";
import { MbiDialog } from "../../../Dialog/category/index";
import EditCategoryDialog from "../../../../components/Dialog/category/EditCategory/EditCategoryDialog";
import LabelsOfCategoryDialog from "../../../../components/Dialog/category/LabelDialog/LabelsOfCategoryDialog";

const CategoryDataTable = () => {
  const toast = useRef<Toast>(null);
  const [filters, setFilters] = useState(categoryFilters);
  const [triggerRefresh, setTriggerRefresh] = useState(true);
  const [isLoading, setlsLoading] = useState(true);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [selectedCategory, setSelectedCategory] =
    useState<ICategory>(initialCategory);
  const [showAddNewDialog, setShowAddNewDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showMbiDialog, setShowMbiDialog] = useState(false);
  const [showLabelDialog, setShowLabelDialog] = useState(false);

  useEffect(() => {
    setlsLoading(true);
    try {
      getCategories().then((response) => {
        //console.log("getCategories.response:", response);
        if (response && response.length > 0) {
          setCategories(response);
          setlsLoading(false);
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  }, [triggerRefresh]);

  const openAddNewDialog = () => {
    setShowAddNewDialog(true);
  };

  const openEditDialog = (rowData?: ICategory) => {
    rowData && setSelectedCategory(rowData);
    //console.log("setSelectedCategory:", rowData);
    setShowEditDialog(true);
  };

  const hideEditDialog = () => {
    setShowEditDialog(false);
  };

  const openMbiDialog = (rowData?: ICategory) => {
    rowData && setSelectedCategory(rowData);
    //console.log("SelectedCategory:", rowData);
    setShowMbiDialog(true);
  };

  const openLabelDialog = (rowData?: ICategory) => {
    rowData && setSelectedCategory(rowData);
    //console.log("rowData:", rowData);
    setShowLabelDialog(true);
  };

  const hideMbiDialog = () => {
    setShowMbiDialog(false);
  };

  const hideLabelDialog = () => {
    setShowLabelDialog(false);
  };

  const refreshTable = () => {
    setTriggerRefresh(!triggerRefresh);
  };

  return (
    <>
      <Toast ref={toast} />
      <Row style={{ marginBottom: "20px", textAlign: "right" }}>
        <Button
          label={"New"}
          icon="pi pi-plus"
          className="p-button-success mr-2"
          onClick={openAddNewDialog}
        />
      </Row>
      <Row>
        <DataTableCrud
          value={categories}
          selection={selectedCategory}
          onSelectionChange={(e) => setSelectedCategory(e.value)}
          header={
            <DataTableSearchHeader
              title="Catgory List"
              filters={filters}
              onChange={(e: any) => setFilters(e)}
            />
          }
          filters={filters}
          loading={isLoading}
          title="categories"
        >
          <Column
            field="name"
            header="Name"
            body={(rowData) => <>{rowData.name}</>}
            sortable
          />
          <Column
            field="main"
            header="Main"
            body={(rowData) => (
              <>
                {rowData.main ? (
                  <Badge value="OK" severity="success" />
                ) : (
                  <Badge value="NOK" severity="danger" />
                )}
              </>
            )}
            sortable
          />
          <Column
            field="menuVisibility"
            header="Visibilitiy"
            body={(rowData) => (
              <>
                {rowData.menuVisibility ? (
                  <Badge value="OK" severity="success" />
                ) : (
                  <Badge value="NOK" severity="danger" />
                )}
              </>
            )}
            sortable
          />

          <Column
            header=""
            style={{ textAlign: "right" }}
            body={(rowData: ICategory) => (
              <>
                <Button
                  icon="pi pi-pencil"
                  className="p-button p-component p-button-rounded p-button-secondary p-button-outlined p-button-icon-only mx-1"
                  onClick={() => openEditDialog(rowData)}
                ></Button>
                <Button
                  icon="pi pi-th-large"
                  className="p-button p-component p-button-rounded p-button-info p-button-outlined p-button-icon-only mx-1"
                  onClick={() => openMbiDialog(rowData)}
                ></Button>
                <Button
                  icon="pi pi-tag"
                  className="p-button p-component p-button-rounded p-button-outlined p-button-icon-only mx-1"
                  onClick={() => openLabelDialog(rowData)}
                ></Button>
              </>
            )}
          />
        </DataTableCrud>
      </Row>
      <MbiDialog
        visible={showMbiDialog}
        onHide={hideMbiDialog}
        selectedCategory={selectedCategory}
      />
      <EditCategoryDialog
        visible={showEditDialog}
        selectedCategory={selectedCategory}
        onHide={hideEditDialog}
        refreshTable={refreshTable}
      />
      <LabelsOfCategoryDialog
        visible={showLabelDialog}
        onHide={hideLabelDialog}
        selectedCategory={selectedCategory}
      />
    </>
  );
};

export default CategoryDataTable;

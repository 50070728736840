import { IVideoContent } from "@/models";
import classNames from "classnames";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { ToggleButton } from "primereact/togglebutton";
import React, { useState } from "react";
import { videoContentSchema } from "../../../../validations/index";
import {
  genres,
  contentTypes,
  externalStatuses,
} from "../../../../constants/index";
import {
  InputField,
  DropdownField,
  ErrorField,
  DataTableDialogFooter,
  MultiSelectField,
} from "../../../index";
import {
  isFormFieldValid,
  capitalizeFirstLetter,
} from "../../../../utils/index";
import { useGetChildsData, useGetSeriesData } from "../../../../hooks/index";

interface VideoContentAddDialogProps {
  visible: boolean;
  videoContent: IVideoContent;
  isLoading: boolean;
  onHide: () => void;
  addVideoContent: (videoContent: IVideoContent) => void;
}

const VideoContentAddDialog: React.FC<VideoContentAddDialogProps> = ({
  visible,
  videoContent,
  isLoading,
  onHide,
  addVideoContent,
}) => {
  const formik = useFormik({
    initialValues: videoContent,
    enableReinitialize: true,
    validationSchema: videoContentSchema,
    onSubmit: (data) => {
      addVideoContent(data);
      formik.resetForm();
      hideDialog();
    },
  });

  const [selectedGenres, setSelectedGenres] = useState(null);
  const [parentSerie, setParentSerie] = useState(null);

  const { series, refetchGetSeries } = useGetSeriesData();
  const { childs, refetchGetChilds } = useGetChildsData(parentSerie);

  const serieOptions = series?.map((serie) => ({
    label: serie.name,
    value: serie.id,
  }));

  const childOptions = childs?.map((child) => ({
    label: child.name,
    value: child.id,
  }));

  const clearValues = () => {
    (formik.values.type === "MOVIE" ||
      formik.values.type === "SERIES" ||
      formik.values.type === "SEASON" ||
      formik.values.type === "EPISODE") ??
      (formik.values.parentId =
        formik.values.seasonNo =
        formik.values.episodeNo =
          null);
  };

  const handleExternalChange = (e: any) => {
    formik.values.vdoId = formik.values.vdoStatus = "";
    formik.handleChange(e);
  };

  const hideDialog = () => {
    onHide();
    formik.resetForm();
  };

  return (
    <Dialog
      visible={visible}
      style={{ width: "450px" }}
      header={"New Content"}
      modal
      className="p-fluid"
      footer={
        <DataTableDialogFooter
          loading={isLoading}
          hideDialog={hideDialog}
          handleSubmit={formik.handleSubmit}
        />
      }
      onHide={hideDialog}
    >
      <form onSubmit={formik.handleSubmit}>
        <InputField
          id="name"
          value={formik.values.name ?? ""}
          type="text"
          required
          autoFocus={true}
          onChange={formik.handleChange}
          labelName="Content Name"
          labelText={capitalizeFirstLetter("Content Name")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name="Content Name" />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Content Name"),
          })}
        />
        {formik.touched.name && formik.errors.name ? (
          <div
            className="error"
            style={{ color: "red", marginBottom: "10px", marginTop: "-10px" }}
          >
            {formik.errors.name}
          </div>
        ) : null}
        <DropdownField
          id="type"
          name="type"
          value={formik.values.type}
          onChange={formik.handleChange}
          onSelect={clearValues}
          options={contentTypes}
          labelName={"Type of Content"}
          labelText={capitalizeFirstLetter("Type of Content")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name={"Type of Content"} />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Type of Content"),
          })}
        />
        {formik.touched.type && formik.errors.type ? (
          <div
            className="error"
            style={{ color: "red", marginBottom: "10px", marginTop: "-10px" }}
          >
            {formik.errors.type}
          </div>
        ) : null}
        <DropdownField
          id="parentSerie"
          name="parentSerie"
          value={formik.values.type === "EPISODE" ? parentSerie : ""}
          onChange={(e) => setParentSerie(e.target.value)}
          options={formik.values.type === "EPISODE" ? serieOptions : []}
          onShow={refetchGetSeries}
          onHide={() => refetchGetChilds()}
          filter
          disabled={formik.values.type !== "EPISODE"}
          labelName={"Parent Serie"}
          labelText={capitalizeFirstLetter("Parent Serie")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name={"Parent Serie"} />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Parent Serie"),
          })}
        />
        <DropdownField
          id="parentId"
          name="parentId"
          value={formik.values.parentId}
          onChange={formik.handleChange}
          options={
            formik.values.type === "SEASON"
              ? serieOptions
              : formik.values.type === "EPISODE"
              ? childOptions
              : []
          }
          onShow={
            formik.values.type === "SEASON" ? refetchGetSeries : undefined
          }
          filter
          disabled={
            formik.values.type !== "SEASON" && formik.values.type !== "EPISODE"
          }
          labelName={"Parent"}
          labelText={capitalizeFirstLetter("Parent")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name={"Parent"} />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Parent"),
          })}
        />
        {formik.touched.parentId && formik.errors.parentId ? (
          <div
            className="error"
            style={{ color: "red", marginBottom: "10px", marginTop: "-10px" }}
          >
            {formik.errors.parentId}
          </div>
        ) : null}
        <ToggleButton
          id="external"
          onLabel="External"
          offLabel="External"
          onIcon="pi pi-check"
          offIcon="pi pi-times"
          checked={formik.values.external}
          onChange={handleExternalChange}
          className="w-9rem"
          style={{ marginBottom: "1rem" }}
        />
        <InputField
          id="vdoId"
          type="number"
          value={String(formik.values.vdoId) ?? ""}
          disabled={!formik.values.external}
          onChange={formik.handleChange}
          labelName="External Id"
          labelText={capitalizeFirstLetter("External Id")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name="External Id" />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "External Id"),
          })}
        />
        <DropdownField
          id="vdoStatus"
          name="vdoStatus"
          value={formik.values.vdoStatus ?? ""}
          disabled={!formik.values.external}
          onChange={formik.handleChange}
          options={externalStatuses}
          labelName={"External Status"}
          labelText={capitalizeFirstLetter("External Status")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name={"External Status"} />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "External Status"),
          })}
        />

        {/* <MultiSelectField
          id="genres"
          name="genres"
          value={selectedGenres}
          onChange={(e) => setSelectedGenres(e.value)}
          options={genres}
          placeholder="Select Genres"
          style={{ marginBottom: "1rem" }}
          labelName="Genre"
          labelText={capitalizeFirstLetter("Genre")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name="Genre" />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Genre"),
          })}
        />
         */}
        <InputField
          id="seasonNo"
          value={String(formik.values.seasonNo) ?? ""}
          type="number"
          disabled={formik.values.type !== "SEASON"}
          onChange={formik.handleChange}
          labelName="Season No"
          labelText={capitalizeFirstLetter("Season No")}
          required={formik.values.type === "SEASON"}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name="Season No" />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Season No"),
          })}
        />
        {formik.touched.seasonNo && formik.errors.seasonNo ? (
          <div
            className="error"
            style={{ color: "red", marginBottom: "10px", marginTop: "-10px" }}
          >
            {formik.errors.seasonNo}
          </div>
        ) : null}
        <InputField
          id="episodeNo"
          value={String(formik.values.episodeNo) ?? ""}
          type="number"
          disabled={formik.values.type !== "EPISODE"}
          onChange={formik.handleChange}
          required={formik.values.type === "EPISODE"}
          labelName="Episode No"
          labelText={capitalizeFirstLetter("Episode No")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name="Episode No" />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Episode No"),
          })}
        />
        {formik.touched.episodeNo && formik.errors.episodeNo ? (
          <div
            className="error"
            style={{ color: "red", marginBottom: "10px", marginTop: "-10px" }}
          >
            {formik.errors.episodeNo}
          </div>
        ) : null}
      </form>
    </Dialog>
  );
};

export default VideoContentAddDialog;

import { NotificationsDataTable } from "../../components/DataTable/notifications";

const Notificationlist = () => {
  return (
    <div className="datatable-crud">
      <div className="card">
        <NotificationsDataTable />
      </div>
    </div>
  );
};

export default Notificationlist;

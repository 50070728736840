import IInvoice from "@/models/Entities/payments/IInvoice";
import { getInvoices, getInvoicesPagination, getPayments, getSubscriptionInfo } from "../../../services";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { DataTableSearchHeader } from "../common";
import { DataTable } from "primereact/datatable";
import moment from "moment";
import SubscriptionInfoDialog from "../../Dialog/payment/SubscriptionInfoDialog";


const InvoiceDataTable = () => {
  const [selectedInvoice, setSelectedInvoice] = useState<IInvoice | undefined>(
    undefined
  );
  const [filters, setFilters] = useState(null);
  const [invoices, setInvoices] = useState<IInvoice[]>();

  const [pagination, setPagination] = useState({ page: 0, size: 30 });

  const hideopenSubscriptionInfoDialog = () => {
    setSelectedInvoice(undefined);
  };

  /*
  const openSubscriptionInfoDialog = (rowData?: IInvoice) => {
    console.log("openSubscriptionInfoDialog");
    rowData && setSelectedInvoice(rowData);
  };
  */

  useEffect(() => {
    getInvoicesPagination(pagination.page, pagination.size)
      .then((res) => {
        //console.log("getInvoices:", res);
        setInvoices(res.content);
      })
      .catch((err) => {
        //console.log("Error Text:", err);
        if (err.response.status === 401) {
          //handleLogout();
          //navigate("/");
          //window.location.href = "/logout";
          console.log("err.response.status:401");
        }
      });
  }, []); // İzleme listesi (dependency array)

  const handlePageChange = (event: any) => {
    setPagination({ page: event.page, size: event.rows });
  };
 
  return (
    <>
      <DataTable
        value={invoices}
        paginator
        rows={pagination.size}
        first={pagination.page * pagination.size}
        onPage={handlePageChange}
        dataKey="id"
        selection={null}
        onSelectionChange={(e) => setSelectedInvoice(e.value as IInvoice)}
        //filters={filters}
        //loading={getProductsIsLoading}
        title="invoices"
        header={
          <DataTableSearchHeader
            title={"Invoice List"}
            filters={filters}
            onChange={(e: any) => setFilters(e)}
          />
        }
      >
        <Column
          field="id"
          header={"ID"}
          sortable
          style={{ minWidth: "4rem", cursor: "pointer" }}
        />
        <Column
          field="customerName"
          header={"Name"}
          sortable
          style={{ minWidth: "10rem" }}
        />
                <Column
          field="email"
          header={"email"}
          sortable
          style={{ minWidth: "10rem" }}
        />
        <Column
          field="invoiceNumer"
          header={"Invoice Number"}
          sortable
          style={{ minWidth: "10rem" }}
        />
        <Column
          field="orderId"
          header={"Subscripton Id"}
          sortable
          style={{ minWidth: "10rem" }}
        />
                <Column
                  field="timestamp"
                  header={"Date"}
                  sortable
                  style={{ minWidth: "5rem" }}
                  body={(rowData) =>
                    rowData.timestamp && (
                      <>{moment(rowData.timestamp).format("DD/MM/YYYY, hh:mm a")}</>
                    )
                  }
                />

        <Column
          field="status"
          header={"Status"}
          filter
          sortable
          style={{ minWidth: "5rem" }}
        />

      </DataTable>
    </>
  );
};

export default InvoiceDataTable;

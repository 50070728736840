import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import {
    getNotificationJobLogs,
} from "../../../../services/customer-services/notificationServices";
import INotificationsJob from "@/models/Entities/messages/INotificationsJob";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import INotificationsLog from "@/models/Entities/messages/INotificationsLog";
import { Badge } from "primereact/badge";

interface NotificationLogsDialogProps {
  visible: boolean;
  onHide: () => void;
  notificationJob?: INotificationsJob;
}

const NotificationLogsDialog: React.FC<NotificationLogsDialogProps> = ({
  visible,
  onHide,
  notificationJob,
}) => {

  const toast = useRef<Toast>(null);
  const [messagesLoading, setMessagesLoading] = useState(true);
  const [notificationLogs, setNotificationLogs] = useState<INotificationsLog[]>([]);
  const [totalElements, setTotalElements] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pagination, setPagination] = useState({ page: 0, size: 10 });

  useEffect(() => {
    if (visible && notificationJob && notificationJob.id) {
        getNotificationJobLogs(notificationJob.id, pagination.page, pagination.size).then(res => {
            console.log("getNotificationJobLogs.res:", res)
            if(res.code === 200){
                setNotificationLogs(res.data.content)
                setTotalElements(res.data.totalElements)
            }
            setMessagesLoading(false)
        })
    } 
  }, [visible, notificationJob]);

  const handlePageChange = (event: any) => {
    setPagination({ page: event.page, size: event.rows });
  };


  return (
    <>
      <Toast ref={toast} />
      <Dialog
        visible={visible}
        style={{ width: "60%" }}
        header={"Notification Logs of " + notificationJob?.title + " | Total count : " + totalElements}
        modal
        className="p-fluid"
        onHide={onHide}
      >
         <DataTable
          value={notificationLogs}
          loading={messagesLoading}
          title="notificationLogs"
          paginator
          rows={pagination.size}
          totalRecords={totalElements || 0}
          first={pagination.page * pagination.size}
          onPage={handlePageChange}
          emptyMessage="No Log found."
          rowsPerPageOptions={[5, 10, 25, 50]}
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
        >
          <Column
            field="id"
            header="ID"
            body={(rowData) => <>{rowData.id}</>}
            sortable
          />
          <Column
            field="customerEmail"
            header="Email"
            body={(rowData) => <>{rowData.customerEmail}</>}
            sortable
          />
          <Column
            field="notificationResult"
            header="Result"
            body={(rowData) => <>{
                rowData.notificationResult ? <Badge value={"Sended"} severity="success" /> : ""
                }</>}
            sortable
          />
          
        </DataTable>

      </Dialog>
    </>
  );
};

export default NotificationLogsDialog;

import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  DataTableStatusTemplate,
  InvoiceDialog,
  IysDialog,
  PaymentStatusDialog,
} from "../../../index";
import { STATUS_TYPES } from "../../../../constants/index";
import styled from "styled-components";
import moment from "moment";
import "primeicons/primeicons.css";
import { Dialog } from "primereact/dialog";
import ISelectedProductP from "../../../../models/Entities/selectedProductP/ISelectedProductP";
import { ProgressSpinner } from "primereact/progressspinner";
import { Badge } from "primereact/badge";
import { useGetCustomerDetails } from "../../../../hooks";
import { Col, Row } from "react-bootstrap";
import { terminateSessionOfUser } from "../../../../services";
import { toastError, toastSuccess } from "../../../../utils";
import { Toast } from "primereact/toast";

const Details = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-right: 3rem;
  font-size: 15px;
  line-height: 1.4;
`;

const CustomerInf = styled("div")`
  display: flex;
  justify-content: space-between;
  min-height: 11rem;
  min-width: 20%;
`;

const Actions = styled("div")`
  display: flex;
  justify-content: end;
  margin-right: 3rem;
`;

const ProductDetails = styled("div")`
  display: flex;
  justify-content: space-between;
  min-width: 45%;
  max-height: 5rem;
`;

const CustomerConfirm = styled("div")`
  display: grid;
  max-width: ;
`;

const CustomerUsage = styled("div")`
  display: grid;
`;

const ActiveStatus = styled("div")`
  -webkit-clip-path: polygon(10% 0%, 100% 1%, 100% 100%, 10% 100%, 0% 50%);
  clip-path: polygon(10% 0%, 100% 1%, 100% 100%, 10% 100%, 0% 50%);
  background-color: turquoise;
  color: white;
  max-width: 5rem;
  display: flex;
  justify-content: end;
  padding-right: 7px;
`;

const InActiveStatus = styled("div")`
  -webkit-clip-path: polygon(10% 0%, 100% 1%, 100% 100%, 10% 100%, 0% 50%);
  clip-path: polygon(10% 0%, 100% 1%, 100% 100%, 10% 100%, 0% 50%);
  background-color: red;
  color: white;
  max-width: 5rem;
  display: flex;
  justify-content: end;
  padding-right: 7px;
  max-width: 90px;
`;

interface CustomerDetailsDialogProps {
  visible: boolean;
  customer: any;
  //isLoading: boolean;
  onHide: () => void;
}

const CustomerDetailsDialog: React.FC<CustomerDetailsDialogProps> = ({
  visible,
  customer,
  //isLoading,
  onHide,
}) => {
  const toast = useRef<Toast>(null);
  const [productP, setProductP] = useState<any>({});
  const [productPs, setProductPs] = useState<any>([]);

  const [subscription, setSubscription] = useState<any>({});
  const [subscriptions, setSubscriptions] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [paymentStatusDialog, setPaymentStatusDialog] =
    useState<boolean>(false);
  const [selectedCustomerDetails, setSelectedCustomerDetails] =
    useState<ISelectedProductP>();

  const { customerd, getCustomerdIsLoading, refetchGetCustomerd } =
    useGetCustomerDetails(customer.id);

  const [iysDataDialog, setIysDataDialog] = useState<boolean>(false);

  const [invoiceDataDialog, setInvoiceDataDialog] = useState<boolean>(false);

  /*  const getProductPs = () => {
    const newProductPs: any = [];
    customerd?.subscriptions.map(
      (
        item: {
          productP: { guid: any; creationDate: any };
          guid: any;
          startDate: any;
        },
        index: string | number
      ) => {
        item.productP.guid = item.guid;
        item.productP.creationDate = item.startDate;
        newProductPs[index] = item.productP;
      }
    );
    setProductPs([...newProductPs]);
  };
 */

  useEffect(() => {
    //console.log("setSubscriptions");
    setSubscriptions(customerd?.subscriptions);
  }, [customerd]);

  /* useEffect(() => {
    customerd?.subscriptions[0]
      ? setProductP(customerd.subscriptions[0].productP)
      : setProductP({});
    getProductPs();
  }, [customerd]);
 */

  const openPaymentStatusDialog = (rowData: any) => {
    //console.log("rowData:", rowData);
    setSelectedCustomerDetails({ ...rowData });
    setPaymentStatusDialog(true);
  };

  const openIysDataDialog = () => {
    //console.log("openIysDataDialog");
    //setSelectedCustomerDetails({ ...rowData });
    setIysDataDialog(true);
  };

  const openInvoiceDataDialog = () => {
    //console.log("openInvoiceDataDialog");
    //setSelectedCustomerDetails({ ...rowData });
    setInvoiceDataDialog(true);
  };

  const terminateSessions = () => {
    //console.log("terminateSessions");
    
    terminateSessionOfUser(customer.id).then((response) => {
      //console.log("terminateSessionOfUser.response:", response)
      if(response && response.code === 200){
        toastSuccess(toast, "Oturumlar kapatıldı. adet:" + response.data);
      } else {
        toastError(toast, "İşlem yapılamadı." + response.message);
      }
    })

  };

  

  const hideDialog = () => {
    onHide();
  };

  return (
    <Dialog
      visible={visible}
      style={{ width: "80%" }}
      header={"Customer Details"}
      modal
      className="p-fluid"
      onHide={hideDialog}
    >
      <Toast ref={toast} />
      {!isLoading ? (
        <div className="grid">
          <div className="col-12 xl:col-12">
            <div
              className="card"
              style={{
                minWidth: "max-content",
                minHeight: "200px",
                display: "grid",
                alignContent: "space-between",
                marginTop: "10px",
              }}
            >
              <Details>
                <CustomerInf>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <h3>
                        {customerd?.name + " "}
                        {customerd?.surname == null ? "" : customerd?.surname}
                      </h3>
                      {customerd?.status === "ACTIVE" ? (
                        <Badge value={customerd?.status} severity="success" />
                      ) : (
                        <Badge value={customerd?.status} severity="warning" />
                      )}
                    </div>
                    <div>
                      <span>Email: {customerd?.email}</span>
                      <br />
                      <span>Type: {customerd?.type}</span>
                      <br />

                      <span>Last Login Date:</span>
                      <span>
                        {customerd?.lastlogindate &&
                          moment(customerd?.lastlogindate).format(
                            "DD/MM/YYYY, h:mm a"
                          )}
                      </span>
                    </div>
                  </div>
                </CustomerInf>
                <ProductDetails>
                  <CustomerConfirm>
                    <div
                      style={{
                        display: "flex",
                        maxWidth: "225px",
                      }}
                    >
                      {customerd?.emailConfirm ? (
                        <i
                          className="pi pi-check-circle"
                          style={{
                            fontSize: "1.5em",
                            marginRight: "8px",
                            color: "green",
                          }}
                        />
                      ) : (
                        <i
                          className="pi pi-times-circle"
                          style={{
                            fontSize: "1.5em",
                            marginRight: "8px",
                            color: "#cccccc",
                          }}
                        />
                      )}
                      Email Confirmation
                    </div>
                    <div
                      style={{
                        display: "flex",
                        minWidth: "225px",
                      }}
                    >
                      {/*}
                      {productP.status === STATUS_TYPES.ACTIVE ? (
                        <i
                          className="pi pi-check-circle"
                          style={{
                            fontSize: "1.5em",
                            marginRight: "8px",
                            color: "green",
                          }}
                        />
                      ) : (
                        <i
                          className="pi pi-times-circle"
                          style={{
                            fontSize: "1.5em",
                            marginRight: "8px",
                            color: "#cccccc",
                          }}
                        />
                      )}
                      Active Product:
                      {
                        productP.status === STATUS_TYPES.ACTIVE ? productP.name : null
                      
                      }
      */}
                    </div>
                  </CustomerConfirm>
                  {/* <CustomerUsage>
                <div></div>
                <div></div>
              </CustomerUsage> */}
                </ProductDetails>
              </Details>
              <div className="flex justify-content-end gap-2">
                <div>
                  <Button
                    label="İleti İzni"
                    className="p-button-outlined"
                    onClick={() => openIysDataDialog()}
                  />
                </div>
                <div>
                  <Button
                    label="Faturalar"
                    className="p-button-outlined"
                    onClick={() => openInvoiceDataDialog()}
                  />
                </div>
                <div>
                  <Button
                    label="Oturumları Kapat"
                    className="p-button-outlined"
                    onClick={() => terminateSessions()}
                  />
                </div>
              </div>

              {/*
              <Actions>
                <SplitButton
                  label="Actions"
                  model={items}
                  className="p-button-info mr-2 mb-2"
                ></SplitButton>
              </Actions>
               */}
            </div>
          </div>
          <div className="col-12 xl:col-12">
            <div>
              <div className="col-12 xl:col-12">
                <div className="card">
                  <h5>Subscriptions</h5>
                  <DataTable
                    value={subscriptions}
                    rows={5}
                    paginator
                    responsiveLayout="scroll"
                  >
                    <Column
                      field="id"
                      header="ID"
                      sortable
                      style={{ width: "5%" }}
                    />
                    <Column
                      field="productP.name"
                      header="Product Name"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="creationDate"
                      header="Subscription Date"
                      sortable
                      style={{ width: "20%" }}
                      body={(rowData) =>
                        rowData.creationDate && (
                          <>
                            {moment(rowData.creationDate).format("DD/MM/YYYY")}
                          </>
                        )
                      }
                    />
                    <Column
                      field="productP.price"
                      header="Price"
                      sortable
                      style={{ width: "20%" }}
                    />
                    <Column
                      field="endDate"
                      header="End Date"
                      sortable
                      style={{ width: "30%" }}
                      body={(rowData) =>
                        rowData.endDate && (
                          <>{moment(rowData.endDate).format("DD/MM/YYYY")}</>
                        )
                      }
                    />
                    <Column
                      field="status"
                      header="Status"
                      sortable
                      style={{ width: "15%" }}
                      body={(rowData) => (
                        <DataTableStatusTemplate status={rowData?.status} />
                      )}
                    />
                    <Column
                      field="channel"
                      header="Channel"
                      sortable
                      style={{ width: "15%" }}
                    />
                    <Column
                      header="Action"
                      style={{ width: "15%" }}
                      body={(rowData) => (
                        <Button
                          icon="pi pi-paypal"
                          className="p-button-secondary p-button-rounded p-button-outlined m-1"
                          onClick={() => openPaymentStatusDialog(rowData)}
                          style={{ width: "2rem", height: "2rem" }}
                        />
                      )}
                    />
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
          {paymentStatusDialog && (
            <PaymentStatusDialog
              visible={paymentStatusDialog}
              onHide={() => setPaymentStatusDialog(false)}
              data={selectedCustomerDetails}
            />
          )}

          {iysDataDialog && (
            <IysDialog
              visible={iysDataDialog}
              onHide={() => setIysDataDialog(false)}
              customer={customer}
            />
          )}

          {invoiceDataDialog && (
            <InvoiceDialog
              visible={invoiceDataDialog}
              onHide={() => setInvoiceDataDialog(false)}
              customer={customer}
            />
          )}
        </div>
      ) : (
        <div
          className="flex align-items-center"
          style={{ height: 700, width: "100%" }}
        >
          <ProgressSpinner style={{ width: "50px", height: "50px" }} />
        </div>
      )}
    </Dialog>
  );
};

export default CustomerDetailsDialog;

import { getPaymentsOfSubscription } from "../../../services";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import IPayment from "src/models/Entities/payments/IPayment";

interface Props {
  subscriptionId: number | undefined | null;
  visible: boolean;
  onHide: () => void;
  // Bileşenin özelliklerini burada tanımlayabilirsiniz
}

const PaymentList: React.FC<Props> = ({ subscriptionId, visible, onHide }) => {
  const [selectedPayment, setSelectedPayment] = useState<IPayment | undefined>(
    undefined
  );

  const [payments, setPayments] = useState<IPayment[]>();

  const hidingDialog = () => {
    setPayments(undefined);
    onHide();
  };

  useEffect(() => {
    //console.log("payments:");
    getPaymentsOfSubscription(subscriptionId)
      .then((res) => {
        //console.log("payments:", res);
        setPayments(res);
      })
      .catch((err) => {
        //console.log("Error Text:", err);
        if (err.response.status === 401) {
          //handleLogout();
          //navigate("/");
          //window.location.href = "/logout";
          console.log("err.response.status:401");
        }
      });
  }, [subscriptionId, visible]);

  return (
    <>
      <Dialog
        header={"Payments of SubId:" + subscriptionId}
        visible={visible}
        position={"center"}
        modal
        style={{ width: "60%" }}
        onHide={hidingDialog}
        draggable={false}
        resizable={false}
      >
        <div className="card" style={{ margin: "10px" }}>
          <DataTable
            value={payments}
            paginator
            rows={30}
            dataKey="id"
            selection={null}
            onSelectionChange={(e) => setSelectedPayment(e.value as IPayment)}
            //filters={filters}
            //loading={getProductsIsLoading}
            title="Payments of subscription"
          >
            <Column
              field="paymentDate"
              header={"Date"}
              sortable
              style={{ minWidth: "15%", width: "15%" }}
              body={(rowData) =>
                rowData.paymentDate && (
                  <>{moment(rowData.paymentDate).format("DD/MM/YYYY, HH:mm")}</>
                )
              }
            />
            <Column
              field="priceWithTax"
              header={"Price With Tax"}
              sortable
              style={{ minWidth: "10%", width: "10%" }}
            />
            <Column
              field="status"
              header={"Status"}
              filter
              sortable
              style={{ minWidth: "15%", width: "15%" }}
            />
            <Column
              field={"msg"}
              header={"Message"}
              sortable
              style={{ minWidth: "40%", width: "40%" }}
            />
          </DataTable>
        </div>
      </Dialog>
    </>
  );
};

export default PaymentList;

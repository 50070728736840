import INotificationsJob from "@/models/Entities/messages/INotificationsJob";
import { axiosInstance } from "../../api/axios.instance";

export const getFcmMessages = async (): Promise<any> =>
  await axiosInstance
    .get(`/crm-core/notification/fcm/all`)
    .then((res) => res.data);

export const getNotificaionLogs = async (data: any): Promise<any> =>
  await axiosInstance
    .get(`/crm-core/notification/fcm/log`, { data })
    .then((res) => res.data);

export const sendSingleNotifictaion = async (
  data: FormData,
  customerId: number
) =>
  await axiosInstance.post(`/crm-core/notification/send/${customerId}`, data);

export const sendNotificationByStatus = async (data: FormData) =>
  await axiosInstance.post(`/crm-core/notification/send/status/`, data);

export const getNotificationJobs = async (): Promise<any> =>
  await axiosInstance
    .get(`/crm-core/notification/jobs`)
    .then((res) => res.data);

export const saveNotificationJob = async (data: INotificationsJob) =>
  await axiosInstance.post(`/crm-core/notification/jobs/`, data);

export const updateNotificationJob = async (data: INotificationsJob) =>
  await axiosInstance.put(`/crm-core/notification/jobs/`, data);

export const activateNotificationJob = async (jobId : number) =>
  await axiosInstance.post(`/crm-core/notification/jobs/activate/${jobId}`);

export const inactivateNotificationJob = async (jobId : number) =>
  await axiosInstance.post(`/crm-core/notification/jobs/inactive/${jobId}`);

export const getNotificationJobLogs = async (jobId : number, page: number, size: number): Promise<any> =>
  await axiosInstance
    .get(`/crm-core/notification/jobs/log/${jobId}`, {
      params: {
        page: page,
        size: size,
      },
    })
    .then((res) => res.data);

import IPaymentInfo from "@/models/Entities/payments/IPaymentInfo";
import { axiosInstance } from "../../api/axios.instance";

// Get Apple Transaction Logs
export const getAppleTransactionLogs = async (
  page: number = 0,
  size: number = 30
): Promise<any> => {
  try {
    const response = await axiosInstance.get(
      `/crm-core/apple/transaction/logs`,
      {
        params: {
          page,
          size,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching Apple transaction logs:", error);
    throw error;
  }
};

// Get Apple Notification Logs
export const getAppleNotificationLogs = async (
  page: number = 0,
  size: number = 30
): Promise<any> => {
  try {
    const response = await axiosInstance.get(
      `/crm-core/apple/notification/logs`,
      {
        params: {
          page,
          size,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching Apple notification logs:", error);
    throw error;
  }
};

// Get Notification Logs by Customer ID
export const getAppleNotificationLogsByCustomerId = async (
  customerGuid: string
): Promise<any> => {
  try {
    const response = await axiosInstance.get(
      `/crm-core/apple/notification/logs/customer/${customerGuid}`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching notification logs by customer ID:", error);
    throw error;
  }
};

// Get Transaction Logs by Customer ID
export const getAppleTransactionLogsByCustomerId = async (
  customerGuid: string
): Promise<any> => {
  try {
    const response = await axiosInstance.get(
      `/crm-core/apple/transaction/logs/customer/${customerGuid}`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching transaction logs by customer ID:", error);
    throw error;
  }
};

// Get Transaction Logs by Original Transaction ID
export const getAppleTransactionLogsByOriginalTransactionId = async (
  originalTransactionId: string
): Promise<any> => {
  try {
    const response = await axiosInstance.get(
      `/crm-core/apple/transaction/logs/original/${originalTransactionId}`
    );
    return response.data.data;
  } catch (error) {
    console.error(
      "Error fetching transaction logs by original transaction ID:",
      error
    );
    throw error;
  }
};

// Get Notification Logs by Original Transaction ID
export const getAppleNotificationLogsByOriginalTransactionId = async (
  originalTransactionId: string
): Promise<IPaymentInfo[]> => {
  try {
    const response = await axiosInstance.get(
      `/crm-core/apple/notification/logs/original/${originalTransactionId}/payment-info`
    );
    return response.data.data;
  } catch (error) {
    console.error(
      "Error fetching notification logs by original transaction ID:",
      error
    );
    throw error;
  }
};

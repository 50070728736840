import { Row } from "react-bootstrap";
import { IDailySubscriptionsData } from "../../../../models";
import { getDailyEndedSubscriptions } from "../../../../services";
import { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import SubscriptionInfoDialog from "../../../../components/Dialog/payment/SubscriptionInfoDialog";
import PaymentList from "../../../../components/Dialog/payment/PaymentList";
import ISubscription from "../../../../models/Entities/customer/ISubscription";

const DailyEndedDataTable = () => {
  const [dailyList, setDailyList] = useState<IDailySubscriptionsData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshList, setRefreshList] = useState(true);

  const [subscription, setSubscription] = useState<IDailySubscriptionsData>();
  const [subDetailDialog, setSubDetailDialog] = useState(false);
  const [showPaymentsDialog, setShowPaymentsDialog] = useState(false);

  useEffect(() => {
    try {
      getDailyEndedSubscriptions().then((response) => {
        //console.log("getDailyEndedSubscriptions.response.data:", response.data);
        if (response && response.data) {
          setDailyList(response.data);
          setIsLoading(false);
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  }, []);

  const triggerRefresh = () => {
    setRefreshList(!refreshList);
  };

  const openSubsDetailsDialog = (subscription?: IDailySubscriptionsData) => {
    subscription && setSubscription(subscription);
    setSubDetailDialog(true);
  };

  const openPaymentsDialog = (subscription?: IDailySubscriptionsData) => {
    subscription && setSubscription(subscription);
    //console.log("subscription", subscription);
    setShowPaymentsDialog(true);
  };

  const hideSubsDetailsDialog = () => {
    setSubDetailDialog(false);
  };

  const hidePaymentListDialog = () => {
    setShowPaymentsDialog(false);
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">Ended Subscriptions</span>
      <Button icon="pi pi-refresh" onClick={triggerRefresh} />
    </div>
  );
  const footer = `In total there are ${
    dailyList ? dailyList.length : 0
  } record.`;

  return (
    <>
      <Row>
        <DataTable
          value={dailyList}
          tableStyle={{ width: "100%" }}
          header={header}
          footer={footer}
        >
          <Column
            field="subId"
            header="Id"
            body={(rowData) => <>{rowData?.subId}</>}
            sortable
          />
          <Column
            field="cstName"
            header="Name"
            body={(rowData) => <>{rowData?.cstName}</>}
            sortable
          />
          <Column
            field="type"
            header="Cst Type"
            body={(rowData) => <>{rowData?.type}</>}
            sortable
          />
          <Column
            field="formattedDate"
            header="Date"
            body={(rowData) => <>{rowData?.formattedDate}</>}
            sortable
          />
          <Column
            field="newStatus"
            header="New Status"
            body={(rowData) => <>{rowData?.newStatus}</>}
            sortable
          />
          <Column
            field="proName"
            header="Product"
            body={(rowData) => <>{rowData?.proName}</>}
            sortable
          />
          <Column
            field="subPrice"
            header="Price"
            body={(rowData) => <>{rowData?.subPrice}</>}
            sortable
          />
          <Column
            body={(rowData: IDailySubscriptionsData) => (
              <>
                <Button
                  icon="pi pi-search"
                  className="p-button-rounded p-button-outlined m-1"
                  onClick={() => openSubsDetailsDialog(rowData)}
                  style={{ width: "2rem", height: "2rem" }}
                />
                <Button
                  icon="pi pi-paypal"
                  className="p-button-secondary p-button-rounded p-button-outlined m-1"
                  onClick={() => openPaymentsDialog(rowData)}
                  style={{ width: "2rem", height: "2rem" }}
                />
              </>
            )}
            exportable={false}
            style={{ width: "10%" }}
          />
        </DataTable>
      </Row>
      <SubscriptionInfoDialog
        subscriptionId={subscription?.subId}
        merchantOid={"" + subscription?.subId}
        visible={subDetailDialog}
        onHide={hideSubsDetailsDialog}
      />
      <PaymentList
        onHide={hidePaymentListDialog}
        subscriptionId={subscription?.subId}
        visible={showPaymentsDialog}
      />
    </>
  );
};

export default DailyEndedDataTable;

import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import { DataTableSearchHeader } from "../../common";
import { dataFilters } from "../../../../constants";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  activateNotificationJob,
  getNotificationJobs,
  inactivateNotificationJob,
} from "../../../../services/customer-services/notificationServices";
import { DataTable } from "primereact/datatable";
import moment from "moment";
import {
  NotificationJobDialog,
  SendNotificationDialog,
} from "../../../../components/Dialog";
import INotificationsJob from "@/models/Entities/messages/INotificationsJob";

import { InputSwitch } from "primereact/inputswitch";
import "primereact/resources/themes/saga-blue/theme.css"; // Veya tercih ettiğin tema
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import NotificationLogsDialog from "../../../../components/Dialog/notificationDia/NotificationDialog/NotificaionLogsDialog";
import "./style.css";
import { Badge } from "primereact/badge";


const NotificationsDataTable = () => {
  const toast = useRef<Toast>(null);
  const [pagination, setPagination] = useState({ page: 0, size: 10 });
  const [triggerRefresh, setTriggerRefresh] = useState(true);
  const [messagesLoading, setMessagesLoading] = useState(true);
  const [showNotificationJobDialog, setShowNotificationJobDialog] =
    useState(false);
    const [showNotificationLogsDialog, setShowNotificationLogsDialog] =
    useState(false);

  const [notificationJobs, setNotificationJobs] = useState<INotificationsJob[]>(
    []
  );
  const [selectedJob, setSelectedJob] = useState<INotificationsJob>();

  //const [customerId, setCustomerId] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [filters, setFilters] = useState(dataFilters);

  useEffect(() => {
    loadData();
  }, [triggerRefresh]);

  const openNotificationJobDialog = () => {
    setSelectedJob(undefined);
    setShowNotificationJobDialog(true);
  };

  const hideSendNotificationDialog = () => {
    setShowNotificationJobDialog(false);
  };

  const hideNotificationLogsDialog = () => {
    setShowNotificationLogsDialog(false);
  };

  const openNotificationLogDialog = (nJob: INotificationsJob) => {
    setSelectedJob(nJob);
    setShowNotificationLogsDialog(true)
  };

  const openNotificationJobDialogForEdit = (nJob: INotificationsJob) => {
    setSelectedJob(nJob);
    setShowNotificationJobDialog(true);
  };

  const formData = new FormData();

  function loadData(): void {
    try {
      getNotificationJobs().then((response) => {
        console.log("getNotificationJobs.response:", response);
        if (response && response.code === 200) {
          setNotificationJobs(response.data);
          //setTotalElements(response.data.totalElements);
          setMessagesLoading(false);
          //setPageCount(response.data.totalPages);
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  }

  /*
  const openMessageDetailsDialog = (rowData?: INotificationsLog) => {
    //rowData && setSelectedMessage(rowData);
    //rowData && setCustomerId(rowData.customerId);
    //setShowMessageResponseDialog(true);
  };

  const updateRefreshTrigger = () => {
    setTriggerRefresh(!triggerRefresh);
  };

  
  const showSuccess = () => {
    toastSuccess(toast, "Operation successful.");
  };

  const showFail = () => {
    toastError(toast, "Operatin failed.");
  };
  */

  const handlePageChange = (event: any) => {
    setPagination({ page: event.page, size: event.rows });
  };

  const handleToggle = (rowData: INotificationsJob) => {
    const newStatus = rowData.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";

    if (rowData.id) {
      if (newStatus === "ACTIVE") {
        activateNotificationJob(rowData?.id).then((res) => {
          if(res.status === 200){
            loadData()
          }
        });
      } else if (newStatus === "INACTIVE") {
        inactivateNotificationJob(rowData?.id).then((resin) => {
          if(resin.status === 200){
            loadData()
          }
        });
      }
    }

    //loadData();
  };

  return (
    <>
      <Toast ref={toast} />
      <Row style={{ marginBottom: "20px", textAlign: "right" }}>
        <Button
          label={"New"}
          icon="pi pi-plus"
          className="p-button-success mr-2"
          onClick={openNotificationJobDialog}
        />
      </Row>
      <Row>
        <DataTable
          value={notificationJobs}
          //selection={selectedMessage}
          //onSelectionChange={(e) => setSelectedMessage(e.value)}
          header={
            <DataTableSearchHeader
              title="Notification Jobs"
              filters={filters}
              onChange={(e: any) => setFilters(e)}
            />
          }
          filters={filters}
          loading={messagesLoading}
          title="notificationJobs"
          //paginator
          //rows={pagination.size}
          //totalRecords={totalElements || 0}
          //first={pagination.page * pagination.size}
          //onPage={handlePageChange}
          emptyMessage="No Log found."
          //rowsPerPageOptions={[5, 10, 25, 50]}
          //currentPageReportTemplate="{first} to {last} of {totalRecords}"
        >
          <Column
            field="id"
            header="ID"
            body={(rowData) => <>{rowData.id}</>}
            sortable
          />
          <Column
            field="title"
            header="Title"
            body={(rowData) => <>{rowData.title}</>}
            sortable
          />
          <Column
            field="message"
            header="Email"
            body={(rowData) => <>{rowData.message}</>}
            sortable
          />
          <Column
            field="date"
            header="Date"
            body={(rowData) => (
              <>{moment(rowData.creationDate).format("DD/MM/YYYY, h:mm a")}</>
            )}
            sortable
          />

          <Column
            field="status"
            header="status"
            body={(rowData) => <>
            {rowData.status === "ACTIVE" ?
             <Badge value={"Active"} severity="success" /> : 
             <Badge value={"Inactive"} severity="secondary" /> 

            }
            </>}
            sortable
          />
          <Column
            field="date"
            header="Send Date"
            body={(rowData) => (
              <>{moment(rowData.sendDate).format("DD/MM/YYYY, h:mm a")}</>
            )}
            sortable
          />
          <Column
            field="activate"
            header="Activate"
            body={(rowData) => (
              <>
                <InputSwitch
                  inputId="switch1"
                  checked={rowData.status === "ACTIVE" ? true : false}
                  onChange={() => handleToggle(rowData)}
                  disabled={rowData.status === "PROCESSED" ? true : false}
                  className={rowData.status === "ACTIVE" ? "custom-switch-on" : "custom-switch-off"} 
                />
              </>
            )}
          />
          <Column
            header="Action"
            body={(rowData: INotificationsJob) => (
              <>
                <Button
                  icon="pi pi-pencil"
                  className="p-button-rounded p-button-info p-button-outlined m-1"
                  onClick={() => openNotificationJobDialogForEdit(rowData)}
                  disabled={rowData.status === "PROCESSED" ? true : false}
                  style={{ width: "2rem", height: "2rem" }}
                ></Button>
                <Button
                  icon="pi pi-search"
                  className="p-button-rounded p-button-outlined m-1"
                  style={{ width: "2rem", height: "2rem" }}
                  onClick={() => openNotificationLogDialog(rowData)}
                />
              </>
            )}
          />
        </DataTable>
      </Row>

      <NotificationJobDialog
        onHide={hideSendNotificationDialog}
        visible={showNotificationJobDialog}
        refreshTable={loadData}
        notificationJob={selectedJob}
      />
      <NotificationLogsDialog
      onHide={hideNotificationLogsDialog}
      visible={showNotificationLogsDialog}
      notificationJob={selectedJob}
      />

    </>
  );
};

export default NotificationsDataTable;

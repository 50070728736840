import IInvoice from "@/models/Entities/payments/IInvoice";
import { getInvoiceDataOfCustomer } from "../../../../services";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { DataTableStatusTemplate } from "../../../index";

interface InvoiceDialogProps {
  visible: boolean;
  customer: any;
  onHide: () => void;
}

const InvoiceDialog: React.FC<InvoiceDialogProps> = ({
  visible,
  customer,
  //isLoading,
  onHide,
}) => {
  const [invocies, setInvocies] = useState<IInvoice[]>([]);

  useEffect(() => {
    //console.log("InvoiceDialog");
    if (customer.id) {
      getInvoiceDataOfCustomer(customer?.email).then((response) => {
        if (response) {
          //console.log("invocies.response:", response);
          setInvocies(response);
        }
      });
    }
  }, [customer]);

  const hideDialog = () => {
    onHide();
  };

  return (
    <Dialog
      visible={visible}
      style={{ width: "80%" }}
      modal
      className="p-fluid"
      onHide={hideDialog}
    >
      <div className="col-12 xl:col-12">
        <div className="card">
          <h5>İleti İzinleri</h5>
          <DataTable value={invocies} rows={5} responsiveLayout="scroll">
            <Column field="id" header="ID" style={{ width: "5%" }} />
            <Column
              field="customerName"
              header="Alıcı"
              sortable
              style={{ width: "25%" }}
            />
            <Column
              field="timestamp"
              header="Tarih"
              style={{ width: "20%" }}
              body={(rowData) =>
                rowData.timestamp && (
                  <>{moment(rowData.timestamp).format("DD/MM/YYYY HH:mm:ss")}</>
                )
              }
            />
            <Column
              field="invoiceNumer"
              header="Fatura No"
              style={{ width: "20%" }}
            />
            <Column
              field="orderId"
              header="Sipariş No"
              style={{ width: "20%" }}
            />
            <Column
              field="packageName"
              header="Paket"
              style={{ width: "20%" }}
            />
            <Column
              field="status"
              header="Status"
              style={{ width: "15%" }}
              body={(rowData) => (
                <DataTableStatusTemplate status={rowData?.status} />
              )}
            />
          </DataTable>
        </div>
      </div>
    </Dialog>
  );
};

export default InvoiceDialog;

import { addPosterImages } from "../../services/index";
import { toastError, toastSuccess } from "../../utils/index";
import { Toast } from "primereact/toast";
import { useMutation, useQueryClient } from "react-query";

export const useAddPosterImagesData = (toast: React.RefObject<Toast>) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading: addPosterImagesIsLoading } = useMutation(
    async (posterImages: FormData) => await addPosterImages(posterImages),
    {
      onSuccess: (data, variables, context) => {
        //console.log("data:", data.data.code);
        if (data.data.code === 200) {
          toastSuccess(toast, "Poster Images Added");
          queryClient.invalidateQueries("posters");
        } else {
          //console.log(data.data.message);
          toastError(toast, "Failed. " + data.data.message);
        }
      },
      onError: (error) => {
        //console.log("error:", error);
        toastError(toast, "Poster Images not Added");
      },
    }
  );
  return { mutate, addPosterImagesIsLoading };
};

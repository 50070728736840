import { addResponse } from "../../../../services";
import { toastError, toastSuccess } from "../../../../utils";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { FormEvent, useEffect, useRef, useState } from "react";
import { DataTableDialogFooter } from "../../common";
import {
  saveNotificationJob,
  updateNotificationJob,
} from "../../../../services/customer-services/notificationServices";
import { DropdownField, InputField } from "../../../FormFields";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { STATUS_TYPES } from "../../../../constants";
import { Calendar } from "primereact/calendar";
import INotificationsJob from "@/models/Entities/messages/INotificationsJob";

interface NotificationJobDialogProps {
  visible: boolean;
  onHide: () => void;
  refreshTable: () => void;
  notificationJob?: INotificationsJob;
}

const NotificationJobDialog: React.FC<NotificationJobDialogProps> = ({
  visible,
  onHide,
  refreshTable,
  notificationJob,
}) => {
  const statusOptions = [
    "ALL",
    STATUS_TYPES.ACTIVE,
    STATUS_TYPES.INACTIVE,
    "TEST",
  ];

  const toast = useRef<Toast>(null);
  const [notificationText, setNotificationText] = useState("");
  const [notificationTitle, setNotificationTitle] = useState("");
  const [sendDate, setSendDate] = useState<Date | null>(null);
  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const [isLoading, setIsLoading] = useState(false);
  //const [notificationJob, setNotificationJob] = useState<INotificationsJob>();

  //const formData = new FormData();

  useEffect(() => {
    if (visible && notificationJob) {
      setNotificationText(notificationJob.message);
      setNotificationTitle(notificationJob.title ? notificationJob.title : "");
      setSendDate(new Date(notificationJob.sendDate));
      setSelectedStatus(notificationJob.customerStatus);
    } else {
      // Reset fields when dialog is closed
      setNotificationText("");
      setNotificationTitle("");
      setSendDate(null);
      setSelectedStatus("ALL");
    }
  }, [visible, notificationJob]);

  const handleSendNotification = () => {
    if (notificationText === "") {
      toastError(toast, "Message can not be empty text.");
      return;
    }

    if (notificationTitle === "") {
      toastError(toast, "Title can not be empty text.");
      return;
    }

    if (!sendDate) {
      toastError(toast, "Send date can not be empty.");
      return;
    }

    const notificationJobData: INotificationsJob = {
      id: notificationJob ? notificationJob.id : null,
      date: null,
      title: notificationTitle,
      message: notificationText,
      source: null,
      customerStatus: selectedStatus,
      status: null,
      creationDate: null,
      sendDate: sendDate.toISOString(),
    };

    //const processedText = notificationText.replace(/\n/g, "\n");
    try {
      if (notificationJobData) {
        const saveOrUpdateJob = notificationJobData.id
          ? updateNotificationJob(notificationJobData) // Call update function if editing
          : saveNotificationJob(notificationJobData);

        saveOrUpdateJob.then((response) => {
          console.log("saveNotificationJob.response:", response);
          if (response.status === 200) {
            //console.log("toastSuccess:");
            toastSuccess(toast, "Notificationjob Saved.");
            //showSuccess();
            refreshTable();
          } else {
            //console.log("atoastError:");
            toastError(
              toast,
              "Save Notificationjob failed. " + response.data.message
            );
          }
        });
      }
    } catch (error) {
      console.log("Fail:", error);
      toastError(toast, "Save NotificationJob failed. " + error);
    }

    refreshTable();
    setNotificationText("");
    setNotificationTitle("");
    setSelectedStatus("ALL");
    setSendDate(null);
    onHide();
  };

  const showSuccess = () => {
    toastSuccess(toast, "Operation successful.");
  };

  function handleTypeChange(e: DropdownChangeEvent): void {
    console.log("handleTypeChange:", e);
    setSelectedStatus(e.value);
  }

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        visible={visible}
        style={{ width: "40%" }}
        header={"Notification Job"}
        modal
        className="p-fluid"
        onHide={onHide}
        footer={
          <DataTableDialogFooter
            loading={isLoading}
            hideDialog={onHide}
            handleSubmit={handleSendNotification}
          />
        }
      >
        <DropdownField
          id="statusSelection"
          name="status"
          value={selectedStatus}
          onChange={handleTypeChange}
          options={statusOptions}
          labelName={"Status"}
          labelText={"Status"}
        />

        <InputField
          id="title"
          value={notificationTitle}
          type="text"
          required
          autoFocus={true}
          onChange={(e) => {
            setNotificationTitle(e.target.value);
          }}
          labelName="Title"
          labelText="Title"
        />

        <InputField
          id="text"
          value={notificationText}
          type="text"
          required
          onChange={(e) => setNotificationText(e.target.value)}
          labelName="Text"
          labelText="Text"
        />
        <div className="p-field">
          <label htmlFor="sendDate">Send Date</label>
          <Calendar
            id="sendDate"
            value={sendDate}
            onChange={(e) => setSendDate(e.value as Date)}
            showTime
            showSeconds
          />
        </div>
      </Dialog>
    </>
  );
};

export default NotificationJobDialog;

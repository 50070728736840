import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useGetPaymentInfo } from "../../../../hooks/index";
import {
  getAppleNotificationLogs,
  getAppleNotificationLogsByCustomerId,
  getAppleNotificationLogsByOriginalTransactionId,
  getPaymentInfo2,
  paymentsBySubscription,
  paymentsBySubscriptionAsPaymentInfo,
} from "../../../../services";
import IPayment from "@/models/Entities/payments/IPayment";
import moment from "moment";
import IPaymentInfo from "@/models/Entities/payments/IPaymentInfo";

interface Props {
  data: any;
  visible: boolean;
  onHide: () => void;
}

const PaymentStatusDialog: React.FC<Props> = ({ data, visible, onHide }) => {
  const [displayPosition, setDisplayPosition] = useState(false);
  const [position, setPosition] = useState<any>("center");
  const [paymentInfos, setPaymentInfos] = useState<IPaymentInfo[]>();

  useEffect(() => {
    console.log("data:", data);

    if (data.channel === "APPLE") {
      getAppleNotificationLogsByOriginalTransactionId(data.guid).then(
        (response) => {
          //console.log("Apple response:", response);
          setPaymentInfos(response);
        }
      );
    } else {
      paymentsBySubscriptionAsPaymentInfo(data?.id) //getPaymentInfo2("21520230101225900")
        .then((res) => {
          //console.log("payments:", res)
          setPaymentInfos(res);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  return (
    data && (
      <Dialog
        header={"Payment List"}
        visible={visible}
        position={position}
        modal
        style={{ width: "50vw" }}
        onHide={onHide}
        draggable={false}
        resizable={false}
      >
        <div>
          <DataTable
            value={paymentInfos}
            showGridlines
            //responsiveLayout="scroll"
            emptyMessage="No payments found."
          >
            <Column field="paymentNumber" header="Payment Number"></Column>
            <Column
              field="paymentDate"
              header="Date"
              body={(rowData) => (
                <>{moment(rowData.paymentDate).format("DD/MM/YYYY, h:mm a")}</>
              )}
            />
            <Column field="price" header="Price(+Tax)"></Column>
            <Column field="paymentStatus" header="Status"></Column>
            <Column field="message" header="Message"></Column>
          </DataTable>
        </div>
      </Dialog>
    )
  );
};

export default PaymentStatusDialog;

import { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Badge } from "primereact/badge";
import moment from "moment";
import { getAppleNotificationLogs } from "../../../services"; // Adjust the import path as needed
import { DataTableSearchHeader } from "../common";

const AppleNotificationsDataTable = () => {
  const [notifications, setNotifications] = useState<any[]>([]);
  const [pagination, setPagination] = useState({ page: 0, size: 30 });
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(null);

  useEffect(() => {
    console.log("AppleNotificationsDataTable")
    const fetchNotifications = async () => {
      setLoading(true);
      try {
        const data = await getAppleNotificationLogs(pagination.page, pagination.size);
        console.log("data:", data)
        setNotifications(data.content);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [pagination.page, pagination.size]);

  const handlePageChange = (event: any) => {
    setPagination({ page: event.page, size: event.rows });
  };

  const statusBodyTemplate = (rowData: any) => {
    return (
      <Badge
        value={rowData.status}
        severity={rowData.status === "CONFIRMED_BY_NOTIFICATION" ? "success" : "warning"}
      />
    );
  };

  return (
    <>
      <DataTable
        value={notifications}
        paginator
        rows={pagination.size}
        first={pagination.page * pagination.size}
        onPage={handlePageChange}
        dataKey="id"
        loading={loading}
        header={
          <DataTableSearchHeader
            title={"Notification List"}
            filters={filters}
            onChange={(e: any) => setFilters(e)}
          />
        }
      >
        <Column
          field="id"
          header={"ID"}
          sortable
          style={{ minWidth: "4rem", cursor: "pointer" }}
        />
        <Column
          field="notificationUUID"
          header={"Notification UUID"}
          sortable
          style={{ minWidth: "10rem" }}
        />
        <Column
          field="notificationType"
          header={"Notification Type"}
          sortable
          style={{ minWidth: "10rem" }}
        />
        <Column
          field="signedDate"
          header={"Signed Date"}
          sortable
          style={{ minWidth: "10rem" }}
          body={(rowData) =>
            rowData.signedDate && (
              <>{moment(rowData.signedDate).format("DD/MM/YYYY, hh:mm a")}</>
            )
          }
        />
        <Column
          field="status"
          header={"Status"}
          sortable
          style={{ minWidth: "5rem" }}
          body={statusBodyTemplate}
        />
      </DataTable>
    </>
  );
};

export default AppleNotificationsDataTable;
